const ENV = {
  // BASE_URL: 'http://admin.art-scape.codesfortomorrow.tech'
  BASE_URL: "https://api.artzcape.com"
  // BASE_URL: 'http://localhost:9002'

  // BASE_URL: "http://3c55-223-236-4-26.ngrok.io"

}

export const zeroAddress = "0x0000000000000000000000000000000000000000";
// export const noAddress = null

export default ENV;

