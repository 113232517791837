import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, NavLink, Navigate } from 'react-router-dom';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import styles from './styles.module.scss';
import AuthContext from '@context/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { postApiReq } from 'src/utils/ApiHandlers';
import { useDispatch } from 'react-redux'
import { showToast } from '@redux/action';
import Loader from '@components/Loader';
import { Paper } from '@material-ui/core';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { setUser, isUserLogin } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)
  const recaptchaRef = React.createRef();
  const [form, setForm] = useState({
    email: '',
    password: '',
  })
  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    postApiReq("/authenticate", form)
      .then((res) => {
        if (res.status) {
          localStorage.setItem('user', JSON.stringify({ token: res.data.token, user: res.data.user }))
          setUser((prev) => {
            return {
              ...prev,
              token: res.data.token,
              user: res.data.user,
              isUserLogin: true
            }
          })
          setIsLoading(false)
          navigate('/dashboard');
        }
        else {
          dispatch(showToast({ type: 'error', message: res.error }))
        }
      })
    setIsLoading(false)
  }

  useEffect(() => {
    ValidatorForm.addValidationRule("isMinimum5", (value) => {
      if (value && value.length < 8) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("noblank", (value) => {
      if (value.length === 0 || !value.trim()) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkEmail", (value) => {
      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(value)) {
        return false;
      }
      return true;
    });

  }, []);

  const onChange = (value) => {
    console.log("Captcha value:", value);
  }
  if (isUserLogin)
    return <Navigate to='/dashboard' />
  return (
    <React.Fragment>
      <Loader isLoading={isLoading} />
      <div className={styles.paperBlock}>
        <Paper sx={{ borderRadius: "15px" }}>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar> */}
              <Typography component="h1" variant="h5" mt={3}>
                Sign in
              </Typography>
              <Box component="form" onSubmit={onSubmit} sx={{ mt: 1 }}>
                <ValidatorForm
                  onSubmit={(e) => e.preventDefault()}
                  onError={(errors) => console.log(errors)}
                  instantValidate={true}
                >
                  <TextValidator
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={(event) =>
                      setForm({
                        ...form,
                        email: event.target.value,
                      })}
                    autoFocus
                    validators={["checkEmail", "isMinimum5"]}
                    errorMessages={[
                      "Enter a valid Email",
                      "Minimum of 5 letters",
                    ]}
                  />
                  <TextValidator
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={form.password}
                    onChange={(event) =>
                      setForm({
                        ...form,
                        password: event.target.value,
                      })}
                    validators={["isMinimum5"]}
                    errorMessages={[
                      "Minimum 8 characters are required",
                    ]}
                  />
                </ValidatorForm>
                {/* <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onChange}
                  className="captcha-box"
                /> */}
                <div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>

                </div>
                <Grid container mb={1}>
                  <Grid item xs>
                    <NavLink className={styles.forgetLink} to="/forget-password" variant="body2">
                      Forgot password?
                    </NavLink>
                  </Grid>
                  <Grid>
                    <NavLink className={styles.forgetLink} style={{ marginLeft: '10px' }} to="#" variant="body2">
                      {"Don't have an account?"}
                    </NavLink>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Paper>
      </div>
    </React.Fragment>
  );
}

export default SignIn;