
import { Alert as MuiAlert, Snackbar } from "@material-ui/core";
import React, { useState, useEffect, Suspense } from 'react'
import Navbar from './Navbar'
import { HIDE_TOAST } from "@redux/action/actionConstants";
import { useDispatch, useSelector } from "react-redux";
import SignIn from '@pages/SignIn';
import { Routes as RouteWrapper, Route, useLocation } from 'react-router-dom';
import { getUserDetails } from "@redux/action";
import ProtectedRoute from './ProtectedRoute';
import  AuthContext from '@context/AuthContext';

const Dashboard = React.lazy(() => import('@containers/Dashboard/index'));
const Collection = React.lazy(() => import('@containers/Collection/index'));
const Nft = React.lazy(() => import('@containers/NFT/index'));
const Creators = React.lazy(() => import('@containers/Creaters/index'));
const ForgetPassword = React.lazy(() => import('@pages/ForgetPassword'));
const ResetPassword = React.lazy(() => import('@pages/ResetPassword'));
const CreatorPreviewData = React.lazy(() => import('@containers/Creaters/CreaterPreviewData'));
const Profile = React.lazy(() => import('@pages/Profile'));
const NotFound = React.lazy(() => import("@pages/NotFound"));
const Ticket = React.lazy(() => import("@containers/Ticket"));
const TicketResponse = React.lazy(() => import("@containers/Ticket/TicketResponse"));
const Activity = React.lazy(() => import("./containers/Activity/index"));
const Launchpad = React.lazy(() => import("./containers/Launchpad"));
const LaunchpadPreviewData = React.lazy(() => import("@containers/Launchpad/LaunchpadPreviewData"));


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    isUserLogin: false,
    token: '',
    user_id: '',
  })
  const location = useLocation();

  useEffect(() => {
    if (localStorage.hasOwnProperty('user')) {
      const USER = localStorage.getItem('user')
      setUser((prev) => {
        return {
          ...prev,
          isUserLogin: true,
          token: USER.token,
          user: USER.user,
        }
      })
      dispatch(getUserDetails());
    }
  }, [dispatch])

  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <>
      <Snackbar
        open={isVisible}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <AuthContext.Provider
        value={{
          ...user,
          setUser,
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>

          <Navbar >
            <RouteWrapper >
              <Route exact path="/" element={<SignIn />} />
              <Route exact path="/forget-password" element={<ForgetPassword />} />
              <Route exact path="/reset-password/:token" element={<ResetPassword />} />
              <Route
                path="/dashboard"
                exact
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/creators"
                element={
                  <ProtectedRoute>
                    <Creators />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/launchpad"
                element={
                  <ProtectedRoute>
                    <Launchpad />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/collection"
                element={
                  <ProtectedRoute>
                    <Collection />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/nft-preview"
                element={
                  <ProtectedRoute>
                    <Nft />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/creatorpreview/:id"
                element={
                  <ProtectedRoute>
                    <CreatorPreviewData />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/launchpadpreview/:id"
                element={
                  <ProtectedRoute>
                    <LaunchpadPreviewData />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              {/* <Route
              exact
              path="/all-transaction"
              element={
                <ProtectedRoute>
                  <AllTransaction />
                </ProtectedRoute>
              }
            /> */}

              {/* <Route
              exact
              path="/placement"
              element={
                <ProtectedRoute>
                  <Placement />
                </ProtectedRoute>
              }
            /> */}
              <Route
                exact
                path="/ticket"
                element={
                  <ProtectedRoute>
                    <Ticket />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/ticket-response/:id"
                element={
                  <ProtectedRoute>
                    <TicketResponse />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/activity"
                element={
                  <ProtectedRoute>
                    <Activity />
                  </ProtectedRoute>
                }
              />

              <Route path="/*" element={<NotFound />} />
            </RouteWrapper >
          </Navbar>
        </Suspense>

      </AuthContext.Provider>
    </>
  );
}

export default App;
