// import ENV from './env';
// export const imageHandler = (path) => {
//   if (path && path.indexOf('http') === -1) {
//     return `${ENV.BASE_URL}/${path}`;
//   }
//   else {
//     return path;
//   }

// }
import ENV from './env';
export const imageHandler = (path, type) => {

  switch (type) {
    case 1:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/user/profile/${path}`;
      }
      else {
        return path;
      }
      break;
    case 2:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/user/banner/${path}`;
      }
      else {
        return path;
      }
      break;
    case 3:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/user/news/${path}`;
      }
      else {
        return path;
      }
      break;
    case 4:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/user/verification/${path}`;
      }
      else {
        return path;
      }
      break;
    case 5:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/collection/logo/${path}`;
      }
      else {
        return path;
      }
      break;
    case 6:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/collection/featured/${path}`;
      }
      else {
        return path;
      }
      break;
    case 7:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/collection/banner/${path}`;
      }
      else {
        return path;
      }
      break;
    case 8:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/token/${path}`;
      }
      else {
        return path;
      }
      break;

    case 9:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/ticket/attachment/${path}`;
      }
      else {
        return path;
      }
      break;
    default:
      if (path && path.indexOf('http') === -1) {
        return `${ENV.BASE_URL}/${path}`;
      }
      else {
        return path;
      }

  }

}