import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';   
import PersonIcon from '@mui/icons-material/Person';
import ImageIcon from '@mui/icons-material/Image';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CollectionsIcon from '@mui/icons-material/Collections';
import AuthContext from '@context/AuthContext'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AssessmentIcon from '@mui/icons-material/Assessment';
import logo from '@assets/Images/logo.png';
import logoIcon from '@assets/Images/logoicon.png'
import { getApiReq } from 'src/utils/ApiHandlers';
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails, showToast } from '@redux/action';
import av from 'src/assets/Images/av.jpg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { imageHandler } from './utils/formatter';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Navbar = ({ children }) => {
  const { user } = useSelector((state) => state.userDetails);
  const theme = useTheme();
  const navigate = useNavigate();
  // const [user, setUser] = useState();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isUserLogin } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    localStorage.clear();
    window.location.href = '/'
  }
  useEffect(() => {
    dispatch(getUserDetails());
  }, [isUserLogin]);

  if (!isUserLogin) {
    return (
      <div>
        {children}
      </div>
    )
  }

  else return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{ backgroundColor: '#4d5268' }} className='appbar' position="fixed" open={open} >
        <Toolbar className='header_content'>
          <div className='header_logo'>
            <IconButton
              style={{ color: "#faa719" }}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 1, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logoIcon} style={{ height: '48px', background: '#4d5268', cursor: 'pointer' }} alt='' onClick={() => navigate('/dashboard')} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img className='adminProfile' src={user?.profile ? imageHandler(user?.profile, 1) : av} alt='' />
            <Typography className='username'>{user?.username || 'Anonymous'}</Typography>
            <Typography
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <div className='username'>
                <ExpandMoreIcon />
              </div>
            </Typography>
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/profile" onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={onSubmit}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img src={logo} style={{ height: '48px', background: '#4d5268', cursor: 'pointer' }} alt='' onClick={() => navigate('/dashboard')} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <StyledLink to='/dashboard'>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </StyledLink>
          <hr />
          <StyledLink to='/creators'>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary='Creators' />
          </StyledLink>
          <hr />
          <StyledLink to='/launchpad'>
            <ListItemIcon>
              <RocketLaunchIcon />
            </ListItemIcon>
            <ListItemText primary='Launchpad' />
          </StyledLink>
          <hr />
          <StyledLink to='/collection'>
            <ListItemIcon>
              <CollectionsIcon />
            </ListItemIcon>
            <ListItemText primary='Collection' />
          </StyledLink>
          <hr />
          <StyledLink to="/nft-preview">
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText primary='NFT' />
          </StyledLink>
          <hr />
          <StyledLink to="/ticket">
            <ListItemIcon>

              <PlaylistAddCheckIcon />

            </ListItemIcon>
            <ListItemText primary='Ticket' />
          </StyledLink>
          <hr />
          <StyledLink to="/activity">
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary='Activity' />
          </StyledLink>
          <hr />
        </List>

      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
export default Navbar;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #27272f;
  display: flex;
  margin-left: 15px;  
`;