import React from 'react';
import { Navigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {

  return localStorage.hasOwnProperty('user') ? children : <Navigate to="/" />
};

export default ProtectedRoute
